import {
  Box,
  Flex,
  useColorModeValue,
  useColorMode,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  IconButton,
  BoxProps,
  FlexProps,
} from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactText } from 'react'
import { RiMoonFill, RiSunLine } from 'react-icons/ri'

import FPLogo from '../../../public/foolproof-logo.svg'

import { ConnectButton } from './ConnectButton'

interface LinkItemProps {
  name: string
  href: string
}

interface SidebarProps {
  isOpen: boolean
  onClose: () => void
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'FoolProof Token', href: '/' },
  { name: 'Revoke', href: '/revoke' },
]

export default function Sidebar(props: SidebarProps) {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} position={'relative'}>
      <SidebarContent onClose={props.onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={props.isOpen}
        placement="left"
        onClose={props.onClose}
        returnFocusOnClose={false}
        onOverlayClick={props.onClose}
        size="xs"
      >
        <DrawerContent>
          <DrawerCloseButton />
          <MobileSidebarContent onClose={props.onClose} />
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

interface SidebarContentProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ ...rest }: SidebarContentProps) => {
  return (
    <Box
      // bg={useColorModeValue('white', 'gray.900')}

      position="sticky"
      top={0}
      bg={useColorModeValue('slate.100', 'slate.900')}
      px={7}
      py={3}
      h="full"
      {...rest}
    >
      {LinkItems.map((link) => (
        <NavItem key={link.name} href={link.href}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}

const MobileSidebarContent = ({ onClose }: SidebarContentProps) => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <>
      <DrawerHeader>
        {' '}
        <FPLogo fill={useColorModeValue('black', 'white')} width="130px" />
      </DrawerHeader>
      <DrawerBody>
        <Box>
          {LinkItems.map((link) => (
            <NavItem onClick={onClose} key={link.name} href={link.href}>
              {link.name}
            </NavItem>
          ))}
        </Box>
      </DrawerBody>

      <DrawerFooter>
        <ConnectButton />
        <IconButton
          aria-label="theme toggle"
          ml="3"
          icon={colorMode === 'light' ? <RiMoonFill /> : <RiSunLine />}
          onClick={toggleColorMode}
        />
      </DrawerFooter>
    </>
  )
}

interface NavItemProps extends FlexProps {
  children: ReactText
  href: string
}
const NavItem = ({ children, href, ...rest }: NavItemProps) => {
  const router = useRouter()
  const colormodeVal = useColorModeValue('brand.200', 'brand.900')
  const colormodeValTxt = useColorModeValue('brand.800', 'brand.300')
  return (
    <Link href={href} style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        px="3"
        py="2"
        my="3"
        minW="11rem"
        borderRadius="md"
        role="group"
        cursor="pointer"
        fontSize="sm"
        fontWeight={router.asPath === href ? 'semibold' : ''}
        textColor={router.asPath === href ? colormodeValTxt : ''}
        bgColor={router.asPath === href ? colormodeVal : ''}
        _hover={{
          bg: useColorModeValue('slate.200', 'slate.800'),
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Link>
  )
}
