import { CheckIcon } from '@chakra-ui/icons'
import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Input,
  FormHelperText,
  FormControl,
  FormLabel,
  useToast,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import React, { useRef, useState } from 'react'
import { isAddress } from 'viem'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useEnsAddress,
} from 'wagmi'

import { useDebounce } from '@/hooks/useDebounce'
import { shadowAbi } from 'abi/Shadow'
import { config as appConfig } from 'config'

import { SelectedToken } from './types'

interface ISendToLoginDialogue {
  selectedToken: SelectedToken | null
  onClose: () => void
}

const checkValidEns = (address: string) => {
  return address?.split('.')?.at(-1) === 'eth'
}

export const SendToLoginDialogue = (props: ISendToLoginDialogue) => {
  const cancelRef = useRef(null)
  const [loginWalletAddress, setLoginWalletAddress] = useState<string>('')

  const isValidAddress = isAddress(loginWalletAddress)

  const debouncedEnsName = useDebounce(loginWalletAddress, 500)

  const { data: ensResolvedAddress, isLoading: ensResolveLoading } = useEnsAddress({
    name: debouncedEnsName,
    enabled: checkValidEns(loginWalletAddress),
  })

  const addRecentTransaction = useAddRecentTransaction()
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top-right',
    containerStyle: {
      marginTop: 20,
    },
  })

  const { config: setSurragateConfig } = usePrepareContractWrite({
    address: props.selectedToken?.FPTAddress,
    abi: shadowAbi as any,
    functionName: 'setSurrogate',
    chainId: appConfig.chainId,
    args: [
      props.selectedToken?.tokenId,
      ensResolvedAddress ? ensResolvedAddress : loginWalletAddress,
    ],
    enabled:
      Boolean(props.selectedToken?.tokenId) && (isValidAddress || Boolean(ensResolvedAddress)),

    onError(err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
      })
    },
  })

  const {
    data: setSurragateData,
    isLoading: isSetSurrogateLoading,
    write: writeSetSurragate,
  } = useContractWrite({
    ...setSurragateConfig,
    onSuccess(data) {
      props.onClose()
      toast({
        title: 'Transaction submitted',
        description: 'Check account for details',
      })
      addRecentTransaction({
        hash: data.hash,
        description: 'Send token to login wallet',
      })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSuccess: txnSuccess } = useWaitForTransaction({
    hash: setSurragateData?.hash,
    onSuccess() {
      toast({
        title: 'Transaction success',
        status: 'success',
      })
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLoginWalletAddress(event.target.value)

  const isDisabled = !(
    writeSetSurragate && (isValidAddress ? !!loginWalletAddress : !!ensResolvedAddress)
  )

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        colorScheme="slate"
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        isOpen={!!props.selectedToken?.collection}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Send to Holding Wallet</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Please enter your holding wallet address below</AlertDialogBody>
          <AlertDialogBody>
            <FormControl mt="2">
              <FormLabel fontSize="sm">Holding wallet</FormLabel>
              <InputGroup>
                <Input type="text" value={loginWalletAddress} onChange={handleChange} />
                <InputRightElement>
                  {ensResolveLoading ? (
                    <Spinner />
                  ) : isValidAddress || ensResolvedAddress ? (
                    <CheckIcon color="green.500" />
                  ) : null}
                </InputRightElement>
              </InputGroup>
              {!isValidAddress && loginWalletAddress !== '' && !ensResolvedAddress && (
                <FormHelperText color="red">Enter a valid address</FormHelperText>
              )}
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isSetSurrogateLoading}
              isDisabled={isDisabled}
              loadingText="Waiting for approval"
              onClick={() => writeSetSurragate?.()}
              ml={3}
            >
              Transfer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

// export const SendToLoginDialogueMemo = React.memo(SendToLoginDialogue)
