import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  useToast,
} from '@chakra-ui/react'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { useRef } from 'react'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, Address } from 'wagmi'

import { useEventLogs } from '@/hooks/useEventLogs'
import { erc20 } from 'abi/ERC20'

interface IRetrieveDialogue {
  onClose: () => void
  selectedRevoke?: {
    spenderAddress: Address
    contractAddress: Address
  }
}

export const RevokeDialogue = (props: IRetrieveDialogue) => {
  const cancelRef = useRef(null)
  const { refetch: refetchEvents } = useEventLogs()

  const addRecentTransaction = useAddRecentTransaction()
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top-right',
    containerStyle: {
      marginTop: 20,
    },
  })

  const revokeAmount = BigInt(0)

  const contractConfig = {
    address: props.selectedRevoke?.contractAddress,
    abi: erc20,
  }

  const { config: revokeConfig } = usePrepareContractWrite({
    ...contractConfig,
    functionName: 'approve',
    enabled: Boolean(props.selectedRevoke?.spenderAddress),
    args: [props.selectedRevoke?.spenderAddress as Address, revokeAmount],
    // overrides: ledgerOverride,

    onError(err: any) {
      console.log('prepare error', err)
      if (err.error.code && err.error.code === -32602) {
        // setLedgerOverride({ type: '0x1' })
      }
      toast({
        title: 'Transaction prepare error',
        description: err.message,
        status: 'error',
      })
    },
  })

  const { data, isLoading, write } = useContractWrite({
    ...revokeConfig,
    onSuccess(data) {
      toast({
        title: 'Transaction submitted',
        description: 'Check account for details',
      })
      addRecentTransaction({
        hash: data.hash,
        description: 'Revoke token',
      })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSuccess: txnSuccess } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess() {
      refetchEvents()
      toast({
        title: 'Transaction success',
        description: 'Check account for details',
        status: 'error',
      })
    },
    onError() {
      toast({
        title: 'Transaction failed',
        description: 'Check account for details',
        status: 'error',
      })
    },
  })

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        isOpen={!!props.selectedRevoke?.contractAddress}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Revoke access</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Are you sure you want to revoke access?</AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              loadingText="Waiting for approval"
              isDisabled={!write || isLoading}
              onClick={() => write?.()}
              colorScheme="brand"
              ml={3}
            >
              Resolve now
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
