import { StyleFunctionProps, extendTheme } from '@chakra-ui/react'
import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const solid = defineStyle({
  background: 'brand.800',
  color: 'white',

  // let's also provide dark mode alternatives
  _dark: {
    background: 'brand.300',
    color: 'slate.800',
  },
})

const outline = defineStyle({
  // background: 'brand.800',
  color: 'brand.800',

  // let's also provide dark mode alternatives
  _dark: {
    color: 'brand.200',
    // background: 'brand.300',
  },
})
const outlineWhite = defineStyle({
  // background: 'brand.800',
  color: 'white',

  // let's also provide dark mode alternatives
  _dark: {
    color: 'white',
    // background: 'brand.300',
  },
})

const buttonTheme = defineStyleConfig({
  variants: { solid, outline, outlineWhite },
})

export const customTheme = extendTheme({
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'Poppins, serif',
  },

  colors: {
    brand: {
      '50': '#f5f2ff',
      '100': '#ede7ff',
      '200': '#ddd2ff',
      '300': '#c3afff',
      '400': '#a781ff',
      '500': '#8e4fff',
      '600': '#812afd',
      '700': '#7319e8',
      '800': '#5f14c3',
      '900': '#45108a',
      '950': '#30096c',
    },
    slate: {
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        color: mode('slate.700', 'slate.300')(props),
      },
    }),
  },

  config: {
    initialColorMode: 'light',
  },
  components: {
    Button: buttonTheme,
    baseStyle: {
      borderRadius: 'full',
    },
  },
})
