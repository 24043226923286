import { useQuery } from '@tanstack/react-query'
import { multicall, readContract } from '@wagmi/core'
import { formatUnits } from 'viem'
import { useAccount, Address, usePublicClient } from 'wagmi'

import { erc20 } from 'abi/ERC20'
import { erc721 } from 'abi/ERC721'

import { getLogs } from '../utils/getLogs'

const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'

const logSorterChronological = (a: any, b: any) => {
  if (a.blockNumber < b.blockNumber) return -1

  if (a.blockNumber > b.blockNumber) return 1

  if (a.transactionIndex < b.transactionIndex) return -1

  if (a.transactionIndex > b.transactionIndex) return 1

  if (a.logIndex < b.logIndex) return -1

  if (a.logIndex > b.logIndex) return 1

  return 0
}

const filteredAllowancesBySpender = (allowances: any) => {
  return allowances.filter(
    (value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => t.topics[2] === value.topics[2])
  )
}

const getAddressFromTopic = (topic: string): Address => {
  return ('0x' + topic.substring(26)) as Address
}

export function useEventLogs() {
  const { address } = useAccount()
  const publicClient = usePublicClient({ chainId: 1 })

  const queryFn = async () => {
    const latestBlockNumber = await publicClient.getBlockNumber()
    // const blockFilter = { fromBlock: 0, toBlock: latestBlockNumber }

    const approval = getLogs(publicClient, null, erc721, 'Approval', 0n, latestBlockNumber, {
      owner: address,
    })

    const approvalForAll = getLogs(
      publicClient,
      null,
      erc721,
      'ApprovalForAll',
      0n,
      latestBlockNumber,
      {
        owner: address,
      }
    )

    const transferTo = getLogs(publicClient, null, erc721, 'Transfer', 0n, latestBlockNumber, {
      from: address,
    })

    const [approvalEvents, approvalForAllEvents, transferToEvents] = await Promise.all([
      approval,
      approvalForAll,
      transferTo,
    ])

    const allEvents = [...approvalEvents, ...approvalForAllEvents, ...transferToEvents]
    // console.log('all events', allEvents)

    const tokenContracts = allEvents
      .filter((event, i) => i === allEvents.findIndex((other) => event.address === other.address))
      .map(async (event) => {
        const approvalsForAll = approvalForAllEvents.filter((log) => log.address === event.address)
        const approvals = approvalEvents.filter((log) => log.address === event.address)

        //ERC721 if topic length 4
        if (event.topics.length === 4) {
          const erc721Config = {
            abi: erc721,
            address: event.address as Address,
          }

          const unlimitedAllowances = filteredAllowancesBySpender(
            approvalsForAll.sort(logSorterChronological)
          ).map(async (appEvent: any) => {
            const spender = getAddressFromTopic(appEvent.topics[2])

            // const isApprovedForAll = appEvent.data !== '0x'

            // if (isApprovedForAll) {
            // const { timestamp } = await provider.getBlock(appEvent.blockNumber)
            return {
              spender, // to adrs
              spenderContractName: '',
              timestamp: '',
              transactionHash: appEvent.transactionHash,
            }
            // }
          })

          const limitedAllowances = filteredAllowancesBySpender(
            approvals.sort(logSorterChronological)
          ).map(async (appEvent: any) => {
            const tokenId = BigInt(appEvent.topics[3]).toString()

            const spender = getAddressFromTopic(appEvent.topics[2])
            // const { timestamp } = await provider.getBlock(appEvent.blockNumber)
            if (spender === ADDRESS_ZERO) return

            try {
              const [owner, spender] = (await multicall({
                allowFailure: false,
                contracts: [
                  { ...erc721Config, functionName: 'ownerOf', args: [BigInt(tokenId)] },
                  {
                    ...erc721Config,
                    functionName: 'getApproved',
                    args: [BigInt(tokenId)],
                  },
                ],
              })) as string[]

              console.log(' limited allowance owner spender', { owner, spender })
              const expectedOwner = getAddressFromTopic(appEvent.topics[1])
              if (owner !== expectedOwner) return

              return {
                spender,
                spenderContractName: '',
                owner,
                timestamp: '',
                tokenId,
                transactionHash: appEvent.transactionHash,
              }
            } catch (err) {
              console.log('ERROR: Limited allowance get ownderOf and getApproved', err)
            }
          })

          const allAllowances = [...unlimitedAllowances, ...limitedAllowances]

          const filteredAllowances = await Promise.all(allAllowances)
          console.log({ filteredAllowances })

          if (allAllowances.length) {
            try {
              const [name, value] = (await multicall({
                allowFailure: false,

                contracts: [
                  { ...erc721Config, functionName: 'name' },
                  {
                    ...erc721Config,
                    functionName: 'balanceOf',
                    args: [address as Address],
                  },
                ],
              })) as string[]

              const openSeaFloor = 0

              return {
                tokenType: 'erc721',
                contractAddress: event.address,
                contractName: name,
                allowances: filteredAllowances.flat().filter((allowance) => allowance?.spender),
                balance: parseFloat(value),
                floorPrice: openSeaFloor,
                transactionHash: event.transactionHash,
              }
            } catch (error) {
              console.log('erc721 error', error)
            }
          }
        }

        //ERC20 if topic length 3
        if (event.topics.length === 3) {
          const erc20Config = {
            abi: erc20,
            address: event.address as Address,
          }

          const allowances = filteredAllowancesBySpender(
            approvals.sort(logSorterChronological)
          ).map(async (appEvent: any) => {
            // const { timestamp } = await provider.getBlock(appEvent.blockNumber)
            const spender = getAddressFromTopic(appEvent.topics[2])

            try {
              const amount = await readContract({
                ...erc20Config,
                functionName: 'allowance',
                args: [address as Address, spender],
              })
              if (Number(amount) === 0) return

              return {
                spender,
                spenderContractName: '',
                amount,
                timestamp: '',
                transactionHash: appEvent.transactionHash,
              }
            } catch (err) {
              console.log('ERROR: ERC20 get allowance', err)
            }
          })

          if (allowances.length) {
            try {
              const [name, value, decimals, symbol, totalSupply] = (await multicall({
                allowFailure: false,
                contracts: [
                  { ...erc20Config, functionName: 'name' },
                  {
                    ...erc20Config,
                    functionName: 'balanceOf',
                    args: [address as Address],
                  },
                  { ...erc20Config, functionName: 'decimals' },
                  { ...erc20Config, functionName: 'symbol' },
                  { ...erc20Config, functionName: 'totalSupply' },
                ],
              })) as string[]
              const filteredAllowances = await Promise.all(allowances)

              return {
                tokenType: 'erc20',
                contractName: name,
                symbol,
                allowances: filteredAllowances.flat().filter((allowance) => allowance.spender),
                contractAddress: event.address,
                balance: Number(formatUnits(BigInt(value), Number(decimals))),
                decimals,
                totalSupply,
                transactionHash: event.transactionHash,
              }
            } catch (error) {
              console.log('erc20 error', error)
            }
          }
        }
      })

    return await Promise.all(tokenContracts)
  }

  return useQuery({
    enabled: Boolean(address),
    queryKey: [
      {
        entity: 'getEvents',
        address,
      },
    ],
    queryFn,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
    cacheTime: Infinity,
    retry: false,
  })
}
