import { Box, Center, Flex, useColorModeValue, Text, Button, Badge, Image } from '@chakra-ui/react'
import axios from 'axios'
import { Base64 } from 'js-base64'
import React, { useCallback, useEffect, useState } from 'react'

import { ICollection } from './types'

interface ITokenCard {
  tokenId: number
  tokenURI: string
  selectedCollection: ICollection
  isSurrogateTokenOwner: boolean
  onClickSend: (parameter: number) => void
  onClickRetrieve: (parameter: number) => void
}

const IPFS_GATEWAY = 'https://ipfs.io'

const TokenCard = (props: ITokenCard) => {
  const [parsedURI, setParsedURI] = useState<any>('')

  const fetchTokenURI = useCallback(async () => {
    try {
      if (props.tokenURI.toString().startsWith('data:application/json,')) {
        const metadata = JSON.parse(props.tokenURI.substring(22))
        return setParsedURI(metadata.image)
      }

      if (props.tokenURI.toString().includes('data:application/json;base64')) {
        return setParsedURI(
          JSON.parse(
            Base64.decode(String(props.tokenURI).split('data:application/json;base64,')[1])
          ).svg_image_data
        )
      }

      if (props.tokenURI.toString().startsWith('ipfs://')) {
        const uriDataResponse = await axios.get(
          `${IPFS_GATEWAY}/ipfs/${props.tokenURI.toString().slice(6)}`
        )
        if (uriDataResponse) {
          return setParsedURI(
            `${IPFS_GATEWAY}/ipfs/${uriDataResponse.data.image.toString().slice(6)}`
          )
        }
      }

      // else
      if (props.tokenURI.toString().startsWith('https://')) {
        const uriDataResponse = await axios.get(props.tokenURI.toString())
        if (uriDataResponse) {
          if (String(uriDataResponse.data.image).startsWith('ipfs://')) {
            return setParsedURI(
              `${IPFS_GATEWAY}/ipfs/${uriDataResponse.data.image.toString().slice(6)}`
            )
          } else {
            return setParsedURI(uriDataResponse.data.image)
          }
        }
      }
    } catch (err) {
      console.warn(err)
      setParsedURI('')
    }
  }, [props.tokenURI])

  useEffect(() => {
    fetchTokenURI()
  }, [fetchTokenURI, props.tokenURI])

  return (
    <Center w="100%" h="100%">
      <Box
        role={'group'}
        p={3}
        maxW={'330px'}
        w={'full'}
        overflow="hidden"
        bg={useColorModeValue('white', 'gray.800')}
        borderWidth="thin"
        rounded={'md'}
      >
        <Box rounded={'md'} height={'230px'}>
          {' '}
          {/* bgColor="gray.200"> */}
          <Image
            alt="Token image"
            rounded={'md'}
            height={230}
            width={282}
            objectFit={'cover'}
            style={{ margin: '0 auto' }}
            src={parsedURI}
          />
        </Box>
        <Flex alignItems={'center'} justifyContent="space-between" pt={5}>
          <Box>
            <Badge size={'sm'} mb="2" colorScheme={props.isSurrogateTokenOwner ? 'red' : 'green'}>
              {props.isSurrogateTokenOwner ? 'Vulnerable' : 'Secure'}
            </Badge>
            <Text color={'gray.500'} fontSize={'xs'}>
              {props.selectedCollection.name}
            </Text>
            <Text fontSize={'sm'}>
              {props.selectedCollection.tokenName} #{props.tokenId}
            </Text>
          </Box>
          {props.isSurrogateTokenOwner ? (
            <Button onClick={() => props.onClickSend(props.tokenId)} size={'sm'}>
              Send
            </Button>
          ) : (
            <Button onClick={() => props.onClickRetrieve(props.tokenId)} size={'sm'}>
              Retrieve
            </Button>
          )}
        </Flex>
      </Box>
    </Center>
  )
}

export const TokenCardMemo = React.memo(TokenCard)
