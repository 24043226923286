import { Chain, mainnet } from 'wagmi'

const defaultChainId = 1
const devnet: Chain = {
  id: 160000,
  name: 'FoolProof DevNet',
  network: 'FoolProof DevNet',
  nativeCurrency: {
    name: 'FP',
    symbol: 'FP',
    decimals: 18,
  },
  rpcUrls: {
    default: { http: ['https://devnet.foolprooflabs.io'] },
    public: { http: ['https://devnet.foolprooflabs.io'] },
  },
  testnet: true,
}

const getChain = () => {
  if (getChainId(defaultChainId) === 160000) {
    return devnet
  } else {
    return mainnet
  }
}

const getChainId = (defaultId: number): number => {
  const chainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID || defaultId)
  return !isNaN(chainId) ? chainId : defaultId
}

export const config = {
  alchemyId: process.env.NEXT_PUBLIC_ALCHEMY_ID,
  chain: getChain(),
  chainId: getChainId(defaultChainId),
  foolproofApi: process.env.NEXT_PUBLIC_FOOLPROOF_API || 'https://eject.foolprooflabs.io',

  registryAddress:
    process.env.NEXT_PUBLIC_REGISTRY_ADDRESS || '0xF781583039fB1B0A08D7ececEFf53fE2a5908858',
  jsonRPc:
    process.env.NEXT_PUBLIC_JSON_RPC ||
    'https://eth-mainnet.g.alchemy.com/v2/ha7Tw-O8YaIpOR6wZ41TA1hs91l7qPi7',
}
