import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  Td,
  Tr,
  Text,
  Skeleton,
  useColorMode,
} from '@chakra-ui/react'
import axios from 'axios'
import { Base64 } from 'js-base64'
import Image from 'next/image'
import React, { useCallback, useEffect, useState } from 'react'

import { IBulkSelectToken, IFPTColData, IOnSelectBulkTokens, SelectedToken } from './types'

const IPFS_GATEWAY = 'https://ipfs.io'

interface ITokenCard {
  tokenId: number
  tokenURI: string
  collectionData: IFPTColData
  isSurrogateOwner: boolean
  selectedBulkTokens: IBulkSelectToken | null
  onClickSend: (selectedToken: SelectedToken) => void
  onClickRetrieve: (selectedToken: SelectedToken) => void
  onSelectBulkTokens: (selectedToken: IOnSelectBulkTokens) => void
}
export const TokenRow = ({
  tokenId,
  tokenURI,
  collectionData,
  isSurrogateOwner,
  selectedBulkTokens,
  onClickRetrieve,
  onClickSend,
  onSelectBulkTokens,
}: ITokenCard) => {
  const [parsedURI, setParsedURI] = useState<any>('')
  const [isTokenURILoading, setIsTokenURILoading] = useState<boolean>(false)
  const { colorMode } = useColorMode()

  const fetch = useCallback(async () => {
    setIsTokenURILoading(true)
    try {
      if (tokenURI.toString().startsWith('data:application/json,')) {
        const metadata = JSON.parse(tokenURI.substring(22))
        setIsTokenURILoading(false)
        return setParsedURI(metadata.image)
      }

      if (tokenURI.toString().includes('data:application/json;base64')) {
        setIsTokenURILoading(false)
        return setParsedURI(
          JSON.parse(Base64.decode(String(tokenURI).split('data:application/json;base64,')[1]))
            .svg_image_data
        )
      }
      if (tokenURI.toString().startsWith('ipfs://')) {
        const uriDataResponse = await axios.get(
          `${IPFS_GATEWAY}/ipfs${tokenURI.toString().slice(6)}`
        )
        if (uriDataResponse) {
          setIsTokenURILoading(false)
          return setParsedURI(
            `${IPFS_GATEWAY}/ipfs${uriDataResponse.data.image.toString().slice(6)}`
          )
        }
      }
      if (tokenURI.toString().startsWith('https://')) {
        const uriDataResponse = await axios.get(tokenURI.toString())
        if (uriDataResponse) {
          if (String(uriDataResponse.data.image).startsWith('ipfs://')) {
            setIsTokenURILoading(false)
            return setParsedURI(
              `${IPFS_GATEWAY}/ipfs${uriDataResponse.data.image.toString().slice(6)}`
            )
          } else {
            setIsTokenURILoading(false)
            return setParsedURI(uriDataResponse.data.image)
          }
        }
      }
    } catch (err) {
      setIsTokenURILoading(false)
      console.warn(err)
      setParsedURI('')
    }
  }, [tokenURI])

  useEffect(() => {
    fetch()
  }, [fetch, tokenURI])

  return (
    <Tr
      transition={{ color: '0.2s' }}
      sx={{
        transition: 'background-color 0.3s ease-in-out',
      }}
      _hover={{ bg: 'slate.200', _dark: { bg: 'slate.800' } }}
    >
      <Td textAlign="left">
        <Checkbox
          isChecked={
            selectedBulkTokens?.collection === collectionData?.collectionAddress &&
            !!selectedBulkTokens?.tokens.find((t) => t.tokenId === tokenId)
          }
          onChange={() =>
            onSelectBulkTokens({
              tokenId: Number(tokenId),
              collection: collectionData?.collectionAddress,
              status: isSurrogateOwner ? 'vulnerable' : 'secure',
              FPTAddress: collectionData?.FPTAddress,
              parsedURI,
              collectionName: collectionData?.metadata?.name || '',
            })
          }
          disabled={collectionData?.tokens.length === 1}
          colorScheme="brand"
        />
      </Td>
      <Td>
        <Flex alignItems="center">
          <Box pos="relative" display="flex" h="12" w="12" mr="4" overflow="hidden" rounded="lg">
            {!isTokenURILoading ? (
              parsedURI ? (
                <Image src={parsedURI} alt="" style={{ objectFit: 'cover' }} fill unoptimized />
              ) : (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  w="full"
                  h="full"
                  textAlign="center"
                  overflow="hidden"
                  rounded="lg"
                  bg={`${colorMode === 'light' ? 'slate.300' : 'slate.800'}`}
                >
                  <Text fontSize="8" lineHeight="1.4" my="auto">
                    Metadata error
                  </Text>
                </Flex>
              )
            ) : (
              <Skeleton h="12" w="12" />
            )}
          </Box>
          <Text fontSize="sm" fontWeight="semibold">
            {collectionData?.metadata?.name} #{tokenId}
          </Text>
        </Flex>
      </Td>
      <Td w="100px">
        <Badge size={'sm'} mb="2" colorScheme={isSurrogateOwner ? 'red' : 'green'}>
          {isSurrogateOwner ? 'Vulnerable' : 'Secure'}
        </Badge>
      </Td>
      <Td w="100px">
        <Button
          variant="outline"
          onClick={() =>
            isSurrogateOwner
              ? onClickSend({
                  tokenId: tokenId,
                  collection: collectionData?.collectionAddress,
                  FPTAddress: collectionData?.FPTAddress,
                })
              : onClickRetrieve({
                  tokenId: tokenId,
                  collection: collectionData?.collectionAddress,
                  FPTAddress: collectionData?.FPTAddress,
                })
          }
          size={'sm'}
        >
          {isSurrogateOwner ? 'Send FPT' : 'Retrieve FPT'}
        </Button>
      </Td>
    </Tr>
  )
}
