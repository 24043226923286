import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FaTwitter, FaLinkedin } from 'react-icons/fa'

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

const Footer = () => {
  return (
    <Box
      // bg={useColorModeValue('gray.50', 'gray.900')}
      borderTopWidth={'1px'}
      borderColor={useColorModeValue('slate.200', 'slate.700')}
      color={useColorModeValue('gray.700', 'gray.200')}
      px={{ base: 4, md: 12 }}
    >
      <Container
        as={Stack}
        maxW={'full'}
        py={6}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Text fontSize={'xl'} fontWeight="semibold">
          FoolProof Labs
        </Text>
        <Text> © {new Date().getFullYear()} FoolProof Labs. All rights reserved</Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Twitter'} href={'https://twitter.com/FoolProofApp'}>
            <FaTwitter />
          </SocialButton>
          <SocialButton
            label={'YouTube'}
            href={'https://www.linkedin.com/company/foolproof-labs-llc/'}
          >
            <FaLinkedin />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  )
}

export default Footer
