/** @type {import('next-seo').DefaultSeoProps} */
const defaultSEOConfig = {
  title: 'FoolProof Token',
  titleTemplate: '%s | FoolProof Labs',
  defaultTitle: 'FoolProof Labs',
  description: 'The first in-wallet security application for web3.',
  canonical: 'https://foolproof.xyz',
  openGraph: {
    url: 'https://foolproof.xyz',
    title: 'FoolProof Labs',
    description: 'The first in-wallet security application for web3.',
    images: [
      {
        url: '',
        alt: '',
      },
    ],
    site_name: 'FoolProof Labs',
  },
  twitter: {
    handle: '',
    cardType: 'summary_large_image',
  },
}

export default defaultSEOConfig
