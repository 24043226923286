import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Address, useAccount } from 'wagmi'

import { config } from 'config'

interface ICollectionData {
  collection: Address
  tokens: number[]
}

type CollectionResult = ICollectionData[]

export const useWalletNFTs = () => {
  const { address } = useAccount()

  const fetcher = async () => {
    const { data } = await axios.get<CollectionResult>(`${config.foolproofApi}/wallet`, {
      params: { account: address },
    })
    return data
  }
  return useQuery(['walletNFTs', address], fetcher, {
    enabled: !!address,
  })
}
