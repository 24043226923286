import { Address, isAddressEqual } from 'viem'
import { useContractReads } from 'wagmi'

import { erc721 } from 'abi/ERC721'
import { shadowAbi } from 'abi/Shadow'

import { useFPTRegistry } from './useFPTRegistry'
import { useNFTMetadata } from './useNFTMetadata'
import { useWalletNFTs } from './useWalletNFTs'

export const useFPTColData = () => {
  const { data: walletNFTs, isLoading: isWalletNFTsLoading } = useWalletNFTs()
  const { data: registeredCols, isLoading: isRegisteredColsLoading } = useFPTRegistry()

  const shadowAvblCols = walletNFTs?.filter((walletItem) => {
    return registeredCols?.some((shadowItem) =>
      isAddressEqual(shadowItem.main, walletItem.collection)
    )
  })

  console.log('registeredCols', registeredCols)

  console.log('shadowavblcols', shadowAvblCols)

  const { data: nftMetadata, isLoading: isNFTMetaLoading } = useNFTMetadata({
    addresses: shadowAvblCols?.map((z) => z.collection) as string[],
  })

  const getSurrogateContractConfig = shadowAvblCols?.flatMap((col) => {
    const shadowCol = registeredCols?.find((z) => isAddressEqual(z.main, col.collection))
    return col.tokens?.map((token) => ({
      abi: shadowAbi,
      address: shadowCol?.shadow,
      functionName: 'ownerOf',
      args: [token],
    }))
  })

  const getTokenURIConfig = shadowAvblCols?.flatMap((col) => {
    return col.tokens?.map((token) => ({
      abi: erc721,
      address: col.collection,
      functionName: 'tokenURI',
      args: [token],
    }))
  })

  // Get TokenURI
  const { data: tokenURIs, isLoading: isTokenURIsLoading } = useContractReads({
    contracts: getTokenURIConfig,
    allowFailure: false,
    enabled: Boolean(getTokenURIConfig),
  })

  // Get Surrogate
  const { data: surrogateTokenOwner, isLoading: isSurrogateOwnerLoading } = useContractReads({
    contracts: getSurrogateContractConfig,
    enabled: Boolean(getSurrogateContractConfig),
    allowFailure: false,
  })

  let idx = 0
  const FPTColData = shadowAvblCols
    ?.map((col) => {
      return {
        collectionAddress: col.collection,
        FPTAddress: registeredCols?.find(
          (regCol) => col.collection.toLowerCase() === regCol.main.toLowerCase()
        )?.shadow,
        metadata: nftMetadata?.find(
          (meta) => meta.collection.toLowerCase() === col.collection.toLowerCase()
        ),

        tokens: col.tokens?.map((token) => ({
          surrogateOwner: surrogateTokenOwner?.[idx] as Address,
          tokenURI: String(tokenURIs?.[idx++]),
          tokenId: token,
        })),
      }
    })
    .sort((a, b) => {
      if (!a.metadata || !b.metadata) return 0
      return Number(b.metadata.eth_price) - Number(a.metadata.eth_price)
    })

  return {
    FPTColData,
    isLoading:
      isWalletNFTsLoading ||
      isRegisteredColsLoading ||
      isNFTMetaLoading ||
      isSurrogateOwnerLoading ||
      isTokenURIsLoading,
  }
}
