import { Box, Text } from '@chakra-ui/react'
import { Link } from '@chakra-ui/react'
import NextLink from 'next/link'

import { formatAddress } from '@/utils/formatAddress'

export const ContractDetails = (props: any) => {
  return (
    <Box mb="16" mt="6">
      <Text fontSize="4xl" mb="2">
        {props.collection.name}
      </Text>
      <Text fontSize="md">
        Shadow:{' '}
        <Link
          as={NextLink}
          href={`https://etherscan.io/address/${props.collection.shadow}`}
          isExternal
        >
          {formatAddress(props.collection.shadow)}
        </Link>
      </Text>
      <Text fontSize="md">
        ERC721:{' '}
        <Link
          as={NextLink}
          href={`https://etherscan.io/address/${props.collection.main}`}
          isExternal
        >
          {formatAddress(props.collection.main)}
        </Link>
      </Text>
      {/* <Flex mt="3" gap="2">
        <IconButton variant="outline" size="xs" aria-label="" icon={<OpenSeaIcon boxSize="6" />} />
        <IconButton
          variant="outline"
          size="xs"
          aria-label=""
          icon={<WebsiteIcon boxSize="6" height="20px" width="20px" />}
        />
        <IconButton
          variant="outline"
          size="xs"
          aria-label="Send email"
          icon={<TwitterIcon boxSize="6" />}
        />
        <IconButton
          variant="outline"
          size="xs"
          aria-label="Send email"
          icon={<DiscordIcon boxSize="6" />}
        />
      </Flex> */}
    </Box>
  )
}
