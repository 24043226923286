import { Abi, Address, BlockNumber, BlockTag } from 'viem'
import { PublicClient } from 'wagmi'

export type BlockRef = BlockNumber | BlockTag | undefined

export type HexString = `0x${string}`

export type EventLog<TArgs extends object> = {
  address: Address
  args: TArgs
  blockHash: HexString
  blockNumber: BlockNumber
  data: HexString
  eventName: string
  logIndex: number
  removed: boolean
  topics: HexString[]
  transactionHash: HexString
  transactionIndex: number
}

export const getLogs = async <TArgs extends object>(
  publicClient: PublicClient,
  address: Address | null,
  abi: Abi,
  eventName: string,
  fromBlock: BlockRef = 0n,
  toBlock: BlockRef = 'latest',
  args: Record<string, unknown> = {}
): Promise<EventLog<TArgs>[]> => {
  const abiItem = abi.find((item) => item.type === 'event' && item.name === eventName)
  if (abiItem?.type === 'event') {
    const request: any = {
      args,
      fromBlock,
      toBlock,
      event: abiItem,
    }

    if (address) {
      request.address = address
    }

    const logs = await publicClient.getLogs<any>(request)
    return logs as EventLog<TArgs>[]
  } else {
    return []
  }
}
