import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Badge,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Button,
  Link as ChakraLink,
} from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'
import { RiSendPlane2Fill } from 'react-icons/ri'
import { formatEther, isAddressEqual } from 'viem'
import { useAccount } from 'wagmi'

import { formatAddress } from '@/utils/formatAddress'
import { getEtherscanLink } from '@/utils/getEtherscanLink'

import { TokenRow } from './TokenRow'
import { IBulkSelectToken, IFPTColData, IOnSelectBulkTokens, SelectedToken } from './types'

interface CollectionTableProps {
  collectionData: IFPTColData | undefined
  onClickSend: (selectedToken: SelectedToken) => void
  onClickRetrieve: (selectedToken: SelectedToken) => void
  onClickBulkRetrieve: () => void
  onClickBulkSend: () => void
  onSelectBulkTokens: (selectedToken: IOnSelectBulkTokens) => void

  selectedBulkTokens: IBulkSelectToken | null
}

export const CollectionTable = ({
  collectionData,
  onClickSend,
  onClickRetrieve,
  onClickBulkRetrieve,
  onSelectBulkTokens,
  onClickBulkSend,
  selectedBulkTokens,
}: CollectionTableProps) => {
  const { address } = useAccount()

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p={6}
        bgColor="brand.900"
        textColor="slate.200"
        w="full"
        // pos="sticky"
        // top="5rem"
        zIndex={2}
        roundedTop="md"
        // shadow="xl"
      >
        <Flex alignItems="center">
          <Box pos="relative" h="16" w="16" mr="4" overflow="hidden" rounded="md">
            <Image
              src={collectionData?.metadata?.image || ''}
              alt=""
              style={{ objectFit: 'cover' }}
              fill
              unoptimized
            />
          </Box>
          <Flex flexDir="column" gap={1}>
            <Text fontSize="md" fontWeight="bold">
              {collectionData?.metadata?.name}
            </Text>
            <Text fontSize="sm">
              <ChakraLink
                isExternal
                target="_blank"
                rel="noreferrer"
                colorScheme="brand"
                as={Link}
                href={getEtherscanLink(collectionData?.collectionAddress || '')}
              >
                {formatAddress(collectionData?.collectionAddress || '')}
                <ExternalLinkIcon mx="2px" />
              </ChakraLink>
            </Text>
            <Text fontSize="sm">
              FPT:{' '}
              <ChakraLink
                isExternal
                target="_blank"
                rel="noreferrer"
                colorScheme="brand"
                as={Link}
                href={getEtherscanLink(collectionData?.FPTAddress || '')}
              >
                {formatAddress(collectionData?.FPTAddress || '')}
                <ExternalLinkIcon mx="2px" mb="3px" />
              </ChakraLink>
            </Text>
          </Flex>
        </Flex>
        <Flex gap={3} flexDirection="column" justifyContent="space-between" textAlign="right">
          <Text fontSize="sm">
            Floor Price: {formatEther(BigInt(collectionData?.metadata?.eth_price || 0))} ETH
          </Text>
          {selectedBulkTokens?.collection === collectionData?.collectionAddress && (
            <Box>
              <Button
                _active={{ bg: 'brand.700' }}
                _hover={{ bg: 'brand.800' }}
                size="xs"
                variant="ghost"
                mr={3}
              >
                Clear
              </Button>
              <Button
                variant="outline"
                color="slate.300"
                borderColor="brand.700"
                _hover={{ bg: 'brand.800' }}
                _active={{ bg: 'brand.700' }}
                leftIcon={
                  <Badge variant="subtle" borderRadius="full">
                    {selectedBulkTokens?.tokens.length}
                  </Badge>
                }
                rightIcon={<RiSendPlane2Fill />}
                onClick={() =>
                  selectedBulkTokens?.status === 'secure'
                    ? onClickBulkRetrieve()
                    : onClickBulkSend()
                }
                size={'sm'}
              >
                {selectedBulkTokens?.status === 'secure' ? 'Bulk Retrieve FPT' : 'Bulk Send FPT'}
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
      <Box
        borderWidth={1}
        borderTopWidth={0}
        mb={14}
        py={8}
        px={{ sm: 0, md: 6 }}
        w="full"
        roundedBottom="md"
      >
        <Table variant="unstyled" colorScheme="brand">
          <Thead>
            <Tr borderBottomWidth={1}>
              <Th w="50px">
                <Text>Select</Text>
              </Th>
              <Th>Token</Th>
              <Th w="100px" textAlign="right">
                Status
              </Th>
              <Th w="100px" textAlign="right">
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {address &&
              collectionData?.tokens.map(({ tokenId, surrogateOwner, tokenURI }) => (
                <TokenRow
                  key={tokenId}
                  tokenId={tokenId}
                  tokenURI={tokenURI}
                  isSurrogateOwner={isAddressEqual(surrogateOwner, address)}
                  collectionData={collectionData}
                  onClickSend={onClickSend}
                  onClickRetrieve={onClickRetrieve}
                  onSelectBulkTokens={onSelectBulkTokens}
                  selectedBulkTokens={selectedBulkTokens}
                />
              ))}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}
