import { Icon } from '@chakra-ui/react'

export const OpenSeaIcon = (props: React.ComponentProps<typeof Icon>) => {
  return (
    <Icon {...props}>
      <path
        d="m2.952 11.223.056-.088 3.376-5.281a.115.115 0 0 1 .203.014c.564 1.264 1.05 2.836.822 3.815-.097.402-.364.948-.664 1.452a2.658 2.658 0 0 1-.126.215.115.115 0 0 1-.096.05H3.05a.115.115 0 0 1-.099-.177Z"
        fill="currentColor"
      ></path>
      <path
        d="M18 12.204v.836a.12.12 0 0 1-.072.11c-.261.111-1.156.522-1.528 1.04-.95 1.32-1.675 3.21-3.296 3.21H6.34A4.347 4.347 0 0 1 2 13.045v-.077c0-.064.052-.116.116-.116h3.77c.075 0 .13.07.123.143-.026.245.019.496.135.724.224.454.688.738 1.19.738H9.2V13H7.355a.119.119 0 0 1-.096-.187l.066-.098c.175-.248.424-.634.672-1.072.17-.296.334-.612.466-.93.026-.057.048-.116.069-.173.036-.101.073-.196.1-.29.027-.08.048-.165.07-.243.062-.27.089-.555.089-.851 0-.116-.006-.237-.016-.353a4.579 4.579 0 0 0-.038-.38 3.938 3.938 0 0 0-.052-.339 5.71 5.71 0 0 0-.106-.507l-.015-.064c-.032-.116-.059-.226-.096-.342a12.929 12.929 0 0 0-.355-1.051c-.046-.132-.1-.259-.153-.385-.079-.191-.159-.364-.232-.528a7.367 7.367 0 0 1-.101-.212 7.444 7.444 0 0 0-.111-.232c-.027-.058-.057-.111-.079-.164l-.228-.422a.074.074 0 0 1 .084-.108l1.427.387h.004l.005.001.188.052.207.059.076.021v-.848c0-.409.328-.741.733-.741.203 0 .387.083.519.217a.745.745 0 0 1 .215.524V5l.152.043a.12.12 0 0 1 .034.017c.038.028.091.07.159.12.053.043.11.095.18.148a10.83 10.83 0 0 1 .619.544c.232.216.492.47.74.75.069.078.137.158.206.242.07.085.143.17.207.253.084.112.175.228.253.35.038.057.08.116.116.173.102.153.191.312.276.47.036.074.074.154.106.233.094.212.17.428.217.644a.807.807 0 0 1 .03.142v.011c.017.064.022.132.027.201a2.155 2.155 0 0 1-.116.944c-.032.091-.064.186-.105.275-.08.185-.175.37-.287.544a3.063 3.063 0 0 1-.121.196c-.047.068-.095.132-.137.195-.059.08-.122.164-.186.238a8.531 8.531 0 0 1-.444.528c-.053.063-.11.127-.169.184a6.926 6.926 0 0 1-.396.39l-.147.136a.12.12 0 0 1-.078.029h-1.136v1.457h1.429c.32 0 .624-.113.87-.321.083-.073.45-.39.883-.87a.112.112 0 0 1 .055-.033l3.948-1.141a.116.116 0 0 1 .148.112Z"
        fill="currentColor"
      ></path>
    </Icon>
  )
}

export const TwitterIcon = (props: React.ComponentProps<typeof Icon>) => {
  return (
    <Icon {...props}>
      <path
        d="M16.362 6.44c.01.15.01.3.01.451 0 4.61-3.302 9.927-9.34 9.927v-.003A8.896 8.896 0 0 1 2 15.251c.26.034.52.05.781.05a6.347 6.347 0 0 0 4.077-1.496c-1.404-.028-2.636-1.001-3.067-2.422.492.1 1 .08 1.482-.06-1.531-.33-2.633-1.76-2.633-3.42v-.045c.457.27.967.42 1.49.437C2.688 7.27 2.243 5.231 3.114 3.636 4.781 5.816 7.24 7.141 9.88 7.281c-.264-1.211.097-2.481.95-3.333 1.322-1.32 3.402-1.253 4.644.151a6.356 6.356 0 0 0 2.085-.847 3.473 3.473 0 0 1-1.443 1.93A6.23 6.23 0 0 0 18 4.632a6.917 6.917 0 0 1-1.638 1.808Z"
        fill="currentColor"
      ></path>
    </Icon>
  )
}

export const WebsiteIcon = (props: React.ComponentProps<typeof Icon>) => {
  return (
    <Icon {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 10c0-4.416 3.576-8 7.992-8C14.416 2 18 5.584 18 10s-3.584 8-8.008 8C5.576 18 2 14.416 2 10Zm11.176-3.2h2.36a6.424 6.424 0 0 0-3.464-2.848c.48.888.848 1.848 1.104 2.848ZM10 3.632A11.27 11.27 0 0 1 11.528 6.8H8.472A11.27 11.27 0 0 1 10 3.632ZM3.6 10c0 .552.08 1.088.208 1.6h2.704A13.212 13.212 0 0 1 6.4 10c0-.544.048-1.072.112-1.6H3.808A6.594 6.594 0 0 0 3.6 10Zm.864 3.2h2.36c.256 1 .624 1.96 1.104 2.848A6.39 6.39 0 0 1 4.464 13.2Zm0-6.4h2.36c.256-1 .624-1.96 1.104-2.848A6.39 6.39 0 0 0 4.464 6.8ZM10 16.368A11.27 11.27 0 0 1 8.472 13.2h3.056A11.27 11.27 0 0 1 10 16.368ZM8 10c0 .544.056 1.072.128 1.6h3.744A11.77 11.77 0 0 0 12 10c0-.544-.056-1.08-.128-1.6H8.128C8.056 8.92 8 9.456 8 10Zm4.072 6.048a12.52 12.52 0 0 0 1.104-2.848h2.36a6.424 6.424 0 0 1-3.464 2.848ZM13.6 10c0 .544-.048 1.072-.112 1.6h2.704A6.594 6.594 0 0 0 16.4 10c0-.552-.08-1.088-.208-1.6h-2.704c.064.528.112 1.056.112 1.6Z"
        fill="currentColor"
      ></path>
    </Icon>
  )
}

export const DiscordIcon = (props: React.ComponentProps<typeof Icon>) => {
  return (
    <Icon {...props}>
      <path
        d="M15.553 5.021A13.182 13.182 0 0 0 12.253 4a9.45 9.45 0 0 0-.423.864 12.313 12.313 0 0 0-3.659 0A9.173 9.173 0 0 0 7.744 4 13.138 13.138 0 0 0 4.44 5.023c-2.089 3.108-2.655 6.138-2.372 9.124a13.277 13.277 0 0 0 4.048 2.042c.326-.441.617-.91.867-1.405a8.582 8.582 0 0 1-1.365-.654c.115-.084.227-.171.335-.261 2.632 1.212 5.493 1.212 8.094 0 .11.09.222.177.335.26a8.565 8.565 0 0 1-1.368.656c.25.493.54.964.867 1.405a13.253 13.253 0 0 0 4.051-2.043c.332-3.462-.568-6.464-2.378-9.126Zm-8.21 7.29c-.791 0-1.44-.727-1.44-1.611s.635-1.612 1.44-1.612c.803 0 1.451.726 1.438 1.612 0 .884-.635 1.61-1.439 1.61Zm5.315 0c-.79 0-1.439-.727-1.439-1.611s.635-1.612 1.439-1.612 1.452.726 1.438 1.612c0 .884-.634 1.61-1.438 1.61Z"
        fill="currentColor"
      ></path>
    </Icon>
  )
}
