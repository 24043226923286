import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  FormControl,
  FormLabel,
  useToast,
  InputGroup,
  UseDisclosureReturn,
  Select,
} from '@chakra-ui/react'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { SendTransactionResult } from '@wagmi/core'
import React, { ChangeEventHandler, useCallback, useRef, useState } from 'react'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, Address } from 'wagmi'

import { seaportAbi } from 'abi/Seaport'
import { config as appConfig } from 'config'

export const IncrementCounter = (props: UseDisclosureReturn) => {
  const cancelRef = useRef(null)
  const [seaportVersion, setSeaportVersion] = useState<string>(
    '0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC'
  )

  const addRecentTransaction = useAddRecentTransaction()
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top-right',
    containerStyle: {
      marginTop: 20,
    },
  })

  const incrementCounterPrep = usePrepareContractWrite({
    address: seaportVersion as Address,
    abi: seaportAbi,
    functionName: 'incrementCounter',
    chainId: appConfig.chainId,
    //args: [],
    enabled: true,

    onError(err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
      })
    },
  })

  const incrementCounterWrite = useContractWrite({
    ...incrementCounterPrep.config,
    onSuccess(data: SendTransactionResult) {
      props.onClose()
      toast({
        title: 'Transaction submitted',
        description: 'Check account for details',
      })
      addRecentTransaction({
        hash: data.hash,
        description: 'Update counter',
      })
    },
  })

  useWaitForTransaction({
    hash: incrementCounterWrite.data?.hash,
    onSuccess() {
      toast({
        title: 'Transaction success',
        status: 'success',
      })
    },
  })

  const handleIncrementCounter = useCallback(() => {
    incrementCounterWrite.write?.()
  }, [incrementCounterWrite])

  const handleSeaportVersion: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (evt) => {
      setSeaportVersion(evt.target.value)
    },
    [setSeaportVersion]
  )

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        colorScheme="slate"
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        isOpen={props.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Increment Seaport Counter</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <p>
              Use this tool if you think you&apos;ve signed a Seaport (OpenSea) order that will
              drain your wallet.
            </p>

            <p style={{ marginTop: '1em' }}>
              <strong>Note:</strong>
              <ul style={{ marginLeft: '2em' }}>
                <li>this will invalidate OpenSea listings</li>
                <li>it will not cancel a blind transaction signature</li>
              </ul>
            </p>

            <FormControl mt="2">
              <FormLabel fontSize="sm">Seaport Version</FormLabel>
              <InputGroup>
                <Select value={seaportVersion} onChange={handleSeaportVersion}>
                  <option value="0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC">1.5 (current)</option>
                  <option value="0x00000000000001ad428e4906aE43D8F9852d0dD6">1.4</option>
                  <option value="0x0000000000000aD24e80fd803C6ac37206a45f15">1.3</option>
                  <option value="0x00000000000006c7676171937C444f6BDe3D6282">1.2</option>
                  <option value="0x00000000006c3852cbEf3e08E8dF289169EdE581">1.1</option>
                </Select>
              </InputGroup>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={incrementCounterWrite.isLoading}
              isDisabled={incrementCounterWrite.isLoading}
              loadingText="confirming..."
              onClick={handleIncrementCounter}
              ml={3}
            >
              Increment
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

// export const SendToLoginDialogueMemo = React.memo(SendToLoginDialogue)
