import { Button, Spinner, Box } from '@chakra-ui/react'
import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit'

export const ConnectButton = () => {
  return (
    <RainbowConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        const ready = mounted
        const connected = ready && account && chain

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button colorScheme="brand" onClick={openConnectModal}>
                    Connect Wallet
                  </Button>
                )
              }

              if (chain.unsupported) {
                return <Button onClick={openChainModal}>Wrong network</Button>
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <Button variant="outline" onClick={openAccountModal}>
                    {account.hasPendingTransactions && <Spinner mr="4" />}
                    {account.displayName}
                    <Box display={{ base: 'none', md: 'block' }} ml="1">
                      {account.displayBalance ? ` (${account.displayBalance})` : ''}
                    </Box>
                  </Button>
                </div>
              )
            })()}
          </div>
        )
      }}
    </RainbowConnectButton.Custom>
  )
}

export default ConnectButton
