import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { INFTMetadata } from '@/components/types'
import { config } from 'config'

type NFTMetadataResult = INFTMetadata[]

interface NFTMetadataProps {
  addresses: string[]
}

export const useNFTMetadata = ({ addresses }: NFTMetadataProps) => {
  // const { address } = useAccount()
  const fetchMetadataRecursive = async () => {
    const metadata = []
    const chunks = []
    for (let i = 0; i < addresses.length; i += 10) {
      chunks.push(addresses.slice(i, i + 10))
    }
    for (const chunk of chunks) {
      try {
        const res = await axios.get<NFTMetadataResult>(
          `${config.foolproofApi}/floor?collections=${chunk.join('&collections=')}`
        )
        metadata.push(...res.data)
      } catch (error) {
        console.log(error)
      }
    }
    return metadata
  }
  return useQuery(['nftMetadata', addresses], fetchMetadataRecursive, {
    enabled: !!addresses,
  })
}
