import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  useToast,
} from '@chakra-ui/react'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { useRef } from 'react'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

import { shadowAbi } from 'abi/Shadow'
import { config } from 'config'

import { SelectedToken } from './types'

interface IRetrieveDialogue {
  selectedToken: SelectedToken | null
  onClose: () => void
}

export const RetrieveDialogue = (props: IRetrieveDialogue) => {
  const cancelRef = useRef(null)
  const addRecentTransaction = useAddRecentTransaction()

  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top-right',
    containerStyle: {
      marginTop: 20,
    },
  })

  const { config: unsetSurrogateConfig } = usePrepareContractWrite({
    address: props.selectedToken?.FPTAddress,
    abi: shadowAbi as any,
    chainId: config.chainId,
    functionName: 'unsetSurrogate',
    enabled: Boolean(props.selectedToken?.tokenId),
    args: [props.selectedToken?.tokenId],

    onError(err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
      })
    },
  })

  const {
    data: unsetSurragateData,
    isLoading: isUnsetSurrogateLoading,
    write: writeUnsetSurrogate,
  } = useContractWrite({
    ...unsetSurrogateConfig,
    onSuccess(data) {
      props.onClose()
      toast({
        title: 'Transaction submitted',
        description: 'Check account for details',
      })
      addRecentTransaction({
        hash: data.hash,
        description: 'Retrieve token',
      })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSuccess } = useWaitForTransaction({
    hash: unsetSurragateData?.hash,
    onSuccess() {
      toast({
        title: 'Transaction success',
        status: 'success',
      })
    },
  })

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        isOpen={!!props.selectedToken?.collection}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Retrieve token</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Retrieve token to current wallet?</AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isUnsetSurrogateLoading}
              isDisabled={!writeUnsetSurrogate}
              loadingText="Waiting for approval"
              onClick={() => writeUnsetSurrogate?.()}
              colorScheme="brand"
              ml={3}
            >
              Transfer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
