import { useQuery } from '@tanstack/react-query'
import { useAccount, usePublicClient } from 'wagmi'

import { config } from 'config'

import { FPTRegistryAbi } from '../../abi/FPTRegistry'

export function useFPTRegistry() {
  const { address } = useAccount()
  const publicClient = usePublicClient({ chainId: config.chainId })

  const getRegisteredCols = async () => {
    try {
      const filter = await publicClient.createContractEventFilter({
        abi: FPTRegistryAbi,
        address: '0xF781583039fB1B0A08D7ececEFf53fE2a5908858',
        eventName: 'ShadowRegistered',
        fromBlock: 'earliest',
        toBlock: 'latest',
        strict: true,
      })

      const logs = await publicClient.getFilterLogs({ filter })

      return logs?.map(({ args: { name, principal, surrogate } }) => {
        return {
          name: name,
          main: principal,
          shadow: surrogate,
        }
      })
    } catch (error) {
      console.log('SHADOW REGISTERED ERROR', error)
    }
  }

  return useQuery({
    queryKey: [
      {
        entity: 'fptRegistry',
      },
    ],
    queryFn: async () => await getRegisteredCols(),
    enabled: !!address,
  })
}
